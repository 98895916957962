export default [
  {
    title: 'Home',
    icon: 'mdi-home',
    to: 'Home'
  },
  {
    title: 'Dashboard',
    icon: 'mdi-chart-line',
    subMenus: [
      {
        title: 'Sales Dashboard',
        icon: 'mdi-monitor-dashboard',
        to: 'NewDashboard'
      }
      // {
      //   title: 'Visitors Dashboard',
      //   icon: 'mdi-file-chart-outline',
      //   to: 'VisitorsDashboard'
      // }
    ]
  },
  {
    title: 'Products',
    icon: 'mdi-tshirt-crew',
    subMenus: [
      {
        title: 'Product list',
        icon: 'mdi-format-list-bulleted',
        to: 'Products'
      },
      {
        title: 'Product Attributes',
        icon: 'mdi-format-list-bulleted',
        to: 'ProductAttributes'
      },
      {
        title: 'Sorting Product',
        icon: 'mdi-order-numeric-ascending',
        to: 'SortingProduct'
      },
      {
        title: 'Attributes Assigner',
        icon: 'mdi-order-bool-ascending-variant',
        to: 'AttributesAssigner'
      },
      {
        title: 'Product Notified',
        icon: 'mdi-order-bool-ascending-variant',
        to: 'ProductNotified'
      }
      // {
      //   title: 'Sorting Product (Kids)',
      //   icon: 'mdi-order-numeric-ascending',
      //   to: 'SortingProductKids'
      // }
    ]
  },
  {
    title: 'Inventory',
    icon: 'mdi-warehouse',
    subMenus: [
      {
        title: 'Product Inventory',
        icon: 'mdi-format-list-bulleted',
        to: 'ProductInventory'
      },
      {
        title: 'PO Overview',
        icon: 'mdi-format-list-bulleted',
        to: 'PurchasesOrderOverview'
      },
      {
        title: 'In Transit Report',
        icon: 'mdi-clipboard-list-outline',
        to: 'InTransitReport'
      },
      {
        title: 'Delivery Notes',
        icon: 'mdi-format-list-bulleted',
        to: 'DeliveryNoteList'
      },
      {
        title: 'Withdraw Notes',
        icon: 'mdi-format-list-bulleted',
        to: 'WithdrawNoteList'
      },
      {
        title: 'Stock Movement New',
        icon: 'mdi-package-variant',
        to: 'StockMovementNew'
      },
      {
        title: 'Stock Count',
        icon: 'mdi-format-list-bulleted',
        to: 'StockCountList'
      },
      {
        title: 'QR TO CSV',
        icon: 'mdi-qrcode',
        to: 'QRToCsv'
      }
    ]
  },
  // {
  //   title: 'LINE MyShop',
  //   icon: 'mdi-alpha-l-circle',
  //   subMenus: [
  //     {
  //       title: 'Product Mapping',
  //       icon: 'mdi-swap-horizontal',
  //       to: 'LineMyShop'
  //     }
  //   ]
  // },
  {
    title: 'Management',
    icon: 'mdi-chart-line',
    subMenus: [
      {
        title: 'Members',
        icon: 'mdi-account-group',
        to: 'MemberManagement'
      },
      {
        title: 'Celebs',
        icon: 'mdi-account-star',
        to: 'CelebsManagement'
      },
      {
        title: 'Careers',
        icon: 'mdi-account-group-outline',
        to: 'Careers'
      },
      {
        title: 'Supplier Apply',
        icon: 'mdi-factory',
        to: 'SupplierApply'
      },
      {
        title: 'Add Member Spending',
        icon: 'mdi-cash-plus',
        to: 'AddMemberSpending'
      },
      {
        title: 'Check Member Spending',
        icon: 'mdi-account-cash',
        to: 'CheckMemberSpending'
      },
      {
        title: 'Promotions',
        icon: 'mdi-tag-multiple',
        to: 'PromotionManagement'
      },
      {
        title: 'Broadcast',
        icon: 'mdi-bullhorn',
        to: 'Broadcast'
      },
      {
        title: 'Sms Logs',
        icon: 'mdi-message-text',
        to: 'SmsLogs'
      },
      {
        title: 'Line Logs',
        icon: 'mdi-math-log',
        to: 'LineLogs'
      },
      {
        title: 'Reprint Receipt',
        icon: 'mdi-receipt',
        to: 'ReprintReceipt'
      },
      {
        title: 'Export Product Ads',
        icon: 'mdi-file-export',
        to: 'ExportProductAds'
      },
      {
        title: 'Settings',
        icon: 'mdi-cog',
        to: 'MemberSettings'
      }
    ]
  },
  {
    title: 'Prototype',
    icon: 'mdi-clipboard-check-multiple-outline',
    subMenus: [
      {
        title: 'Materials',
        icon: 'mdi-palette-swatch',
        to: 'MaterialsList'
      },
      {
        title: 'Fabrics',
        icon: 'mdi-tshirt-crew',
        to: 'FabricsList'
      },
      {
        title: 'Sketch',
        icon: 'mdi-clipboard-edit-outline',
        to: 'SketchListOld'
      },
      {
        title: 'Order',
        icon: 'mdi-clipboard-list-outline',
        to: 'OrderFormListOld'
      },
      {
        title: 'Manufacture',
        icon: 'mdi-calendar-clock-outline',
        to: 'ManufactureListOld'
      },
      {
        title: 'Sketch (NEW)',
        icon: 'mdi-clipboard-edit-outline',
        to: 'SketchList'
      },
      {
        title: 'Order (NEW)',
        icon: 'mdi-clipboard-list-outline',
        to: 'OrderFormList'
      },
      {
        title: 'Manufacture (NEW)',
        icon: 'mdi-calendar-clock-outline',
        to: 'ManufactureList'
      },
      {
        title: 'Tracking',
        icon: 'mdi-truck-check',
        to: 'TrackingList'
      },
      {
        title: 'Fabric Orders',
        icon: 'mdi-receipt',
        to: 'FabricOrdersList'
      },
      {
        title: 'Product Mapping',
        icon: 'mdi-package',
        to: 'ProductMappingListOld'
      },
      {
        title: 'Product Mapping (NEW)',
        icon: 'mdi-package',
        to: 'ProductMappingList'
      },
      {
        title: 'Attribute Management',
        icon: 'mdi-application-cog',
        to: 'PrototypeAttribute'
      }
    ]
  },
  {
    title: 'Report Hub',
    icon: 'mdi-file-document-multiple-outline',
    to: 'NewReport'
  },
  // {
  //   title: 'Reports',
  //   icon: 'mdi-clipboard-list-outline',
  //   subMenus: [
  //     {
  //       title: 'ReportColor',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'ReportColors'
  //     },
  //     {
  //       title: 'ReportColorSize',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'ReportColorsWithSizes'
  //     },
  //     {
  //       title: 'Pick Pack Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'PickPackReport'
  //     },
  //     {
  //       title: 'DHL Express Pickup Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'DhlPickupReport'
  //     },
  //     {
  //       title: 'Offline Conversion Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'OfflineConversionReport'
  //     },
  //     {
  //       title: 'LINE Linked Report (Branch)',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'BranchLineLinkedReport'
  //     },
  //     {
  //       title: 'LINE Linked Report (Cashier)',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'CashierLineLinkedReport'
  //     },
  //     {
  //       title: 'Cashiers Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewCashierReport'
  //     },
  //     // {
  //     //   title: 'Admin Sales Report',
  //     //   icon: 'mdi-clipboard-list-outline',
  //     //   to: 'NewAdminReport'
  //     // },
  //     {
  //       title: 'Report Group Categories',
  //       icon: 'mdi-monitor-dashboard',
  //       to: 'NewReportCategories'
  //     },
  //     {
  //       title: 'POS Sales Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewPosReport'
  //     },
  //     {
  //       title: 'POS Payment Summary',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'PosPaymentSummary'
  //     },
  //     {
  //       title: 'Online Sales Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewSaleReport'
  //     },
  //     {
  //       title: 'Online International Sales Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewSaleReportInternational'
  //     },
  //     // {
  //     //   title: 'Online Singapore Sales Report',
  //     //   icon: 'mdi-clipboard-list-outline',
  //     //   to: 'NewSaleReportSingapore'
  //     // },
  //     {
  //       title: 'Fulfillment',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewFulfillmentReport'
  //     },
  //     {
  //       title: 'Fulfillment International',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewFulfillmentReportInternational'
  //     },
  //     {
  //       title: 'Sales Product Report',
  //       icon: 'mdi-tshirt-crew-outline',
  //       to: 'SaleProductReport'
  //     },
  //     {
  //       title: 'POS Sales Product Report',
  //       icon: 'mdi-tshirt-crew-outline',
  //       to: 'PosSaleProductReport'
  //     },
  //     // {
  //     //   title: 'Stock Cost / Value',
  //     //   icon: 'mdi-clipboard-list-outline',
  //     //   to: 'StockCostValue'
  //     // }
  //     // {
  //     //   title: 'Tracking Behavior Report (Overall)',
  //     //   icon: 'mdi-list-status',
  //     //   to: 'TrackingBehaviorOverallReport'
  //     // },
  //     // {
  //     //   title: 'Tracking Behavior Report (Warehouse)',
  //     //   icon: 'mdi-list-status',
  //     //   to: 'TrackingBehaviorWarehouseReport'
  //     // },
  //     {
  //       title: 'Report New Member',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'NewMemberReport'
  //     },
  //     {
  //       title: 'Cofen Chat Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'CofenChatReport'
  //     },
  //     {
  //       title: 'Cofen Feedback Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'CofenFeedbackReport'
  //     },
  //     {
  //       title: 'In Dispute Report',
  //       icon: 'mdi-clipboard-list-outline',
  //       to: 'IdpReport'
  //     }
  //   ]
  // },
  {
    title: 'PMS',
    icon: 'mdi-clipboard-list-outline',
    subMenus: [
      {
        title: 'Performance Review',
        icon: 'mdi-clipboard-list-outline',
        to: 'PerformanceReview'
      },
      {
        title: 'Performance Report',
        icon: 'mdi-clipboard-list-outline',
        to: 'PerformanceReport'
      },
      {
        title: 'Employees List',
        icon: 'mdi-clipboard-list-outline',
        to: 'EmployeesList'
      },
      {
        title: 'Daily Briefs Broadcast',
        icon: 'mdi-clipboard-list-outline',
        to: 'BroadcastMessage'
      },
      {
        title: 'Daily Briefs Report',
        icon: 'mdi-clipboard-list-outline',
        to: 'DailyBriefs'
      },
      {
        title: 'Settings',
        icon: 'mdi-cog',
        to: 'PerformanceReportSettings'
      }
    ]
  },
  {
    title: 'Cash Receipt',
    icon: 'mdi-cash',
    subMenus: [
      {
        title: 'Cash Receipt',
        icon: 'mdi-cash',
        to: 'CashReceipt'
      }
    ]
  },
  {
    title: 'Purchases',
    icon: 'mdi-clipboard-list-outline',
    subMenus: [
      {
        title: 'Raw Material',
        icon: 'mdi-box-cutter',
        to: 'PurchasesItems'
      },
      {
        title: 'Vendors',
        icon: 'mdi-face-agent',
        to: 'PurchasesVendors'
      },
      {
        title: 'RM Orders',
        icon: 'mdi-clipboard-list-outline',
        to: 'PurchasesOrders'
      },
      {
        title: 'FG Orders',
        icon: 'mdi-clipboard-list-outline',
        to: 'PurchasesFinishedGoodsOrders'
      },
      {
        title: 'Assembly Orders',
        icon: 'mdi-clipboard-list-outline',
        to: 'PurchasesAssemblyOrders'
      },
      {
        title: 'Assembly BOM',
        icon: 'mdi-clipboard-list-outline',
        to: 'PurchasesAssemblyBOM'
      },
      {
        title: 'Customers',
        icon: 'mdi-face-woman',
        to: 'PurchasesCustomers'
      },
      {
        title: 'UOM',
        icon: 'mdi-size-xl',
        to: 'PurchasesUOM'
      }
    ]
  },
  {
    title: 'Store Reports',
    icon: 'mdi-clipboard-list-outline',
    subMenus: [
      {
        title: 'Stock Aging Report',
        icon: 'mdi-timetable',
        to: 'StockAgingReport'
      },
      {
        title: 'Sales Products Report',
        icon: 'mdi-clipboard-list-outline',
        to: 'SalesProductsReport'
      },
      {
        title: 'Best Seller Report',
        icon: 'mdi-thumb-up-outline',
        to: 'BestSellerReport'
      }
    ]
  },
  {
    title: 'Web Management',
    icon: 'mdi-application-settings',
    to: 'WebManagement'
  },
  {
    title: 'Order Management',
    icon: 'mdi-clipboard-list-outline',
    to: 'OrderList'
  },
  {
    title: 'Tax Invoice',
    icon: 'mdi-note-text',
    to: 'TaxInvoice'
  },
  {
    title: 'QR Label',
    icon: 'mdi-qrcode',
    to: 'QrCode'
  },
  {
    title: 'RFID Label',
    icon: 'mdi-access-point',
    to: 'RFID'
  },
  {
    title: 'Barcode',
    icon: 'mdi-barcode',
    to: 'Barcode'
  },
  // {
  //   title: 'RFID',
  //   icon: 'mdi-cube-scan',
  //   to: 'RFID'
  // }
  {
    title: 'Give feedback',
    icon: 'mdi-alert-outline',
    to: 'Feedback'
  },
  {
    title: 'Gen QR',
    icon: 'mdi-qrcode',
    to: 'GenQR'
  },
  {
    title: 'Production Plan',
    icon: 'mdi-chart-gantt',
    to: 'ProductionPlan'
  }
]
