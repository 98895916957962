import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class OrderProvider extends HttpRequest {
  getOrderList (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/orders', query)
  }

  getOrderMany (payload) {
    this.setHeader(getAuthToken())
    return this.post('/orders/get-order-many', payload)
  }

  getOrderListByWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/orders/warehouse', query)
  }

  getOrderCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/orders/count', query)
  }

  getOrderCountByWarehouse (param, query) {
    this.setHeader(getAuthToken())
    return this.get(`/orders/warehouse/count/${param}`, query)
  }

  getOrderById (param) {
    this.setHeader(getAuthToken())
    return this.get(`/orders/${param}`)
  }

  getOrderWarehouseById (param) {
    this.setHeader(getAuthToken())
    return this.get(`/orders/warehouse/${param}`)
  }

  shipKerry (payload) {
    this.setHeader(getAuthToken())
    return this.post('/kerry/ship', payload)
  }

  createOrderManual (payload) {
    this.setHeader(getAuthToken())
    return this.post('/order/manual', payload)
  }

  createOrderCeleb (payload) {
    this.setHeader(getAuthToken())
    return this.post('/order/manual/celeb', payload)
  }

  createOrderInternational (payload) {
    this.setHeader(getAuthToken())
    return this.post('/order/manual/international', payload)
  }

  createOrderTiktok (payload) {
    this.setHeader(getAuthToken())
    return this.post('/order/manual/tiktok', payload)
  }

  updateOrder (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/orders/${id}`, payload)
  }

  updateStatusToPaid (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/orders/${id}/paid`)
  }

  updateStatusToReadyToShip (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/orders/${id}/ready-to-ship`)
  }

  updateTrackingOrder (id, refCode) {
    this.setHeader(getAuthToken())
    return this.patch(`orders/${id}/tracking`, { refCode })
  }

  updateTrackingOrderDHL (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`orders/${payload.orderId}/tracking`, payload)
  }

  updateStatusToComplete (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/orders/${id}/complete`)
  }

  voidOrder (id, reason) {
    this.setHeader(getAuthToken())
    return this.delete(`/orders/void?id=${id}&reason=${reason}`)
  }

  regenOrder (payload) {
    this.setHeader(getAuthToken())
    return this.post('/orders/regen', payload)
  }

  editShippingAddress (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/orders/${id}/shipping-address`, payload)
  }

  getOrderPromotion (promotionId, payload) {
    this.setHeader(getAuthToken())
    return this.get(`/order/promotion/${promotionId}/id`, payload)
  }
}

export default OrderProvider
