export default {
  path: '/product',
  component: () => import('@/views/products/index.vue'),
  children: [
    {
      path: '',
      name: 'Products',
      component: () => import('@/views/products/page/ProductList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'vm',
          'designer',
          'inventory_and_costing',
          'merchandising_planner',
          'warehouse_manager',
          'merchandiser',
          'creative',
          'graphic',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/sorting-product',
      name: 'SortingProduct',
      component: () => import('@/views/products/page/SortingProduct.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'creative',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/sorting-product-kids',
      name: 'SortingProductKids',
      component: () => import('@/views/products/page/SortingProductKids.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'creative',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: 'create',
      name: 'CreateProduct',
      component: () => import('@/views/products/page/ProductForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'support'
        ]
      }
    },
    {
      path: 'edit/:productId',
      name: 'EditProduct',
      component: () => import('@/views/products/page/ProductForm.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'creative',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: 'attributes',
      component: () => import('@/views/attributes/index.vue'),
      children: [
        {
          path: '',
          name: 'ProductAttributes',
          redirect: { name: 'TagsAttributes' }
        },
        {
          path: 'tags',
          name: 'TagsAttributes',
          component: () => import('@/views/attributes/page/Tags.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'sale-tags',
          name: 'SaleTags',
          component: () => import('@/views/attributes/page/SaleTags.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'online_admin',
              'accounting_manager',
              'support'
            ]
          }
        },
        {
          path: 'color',
          name: 'ColorAttributes',
          component: () => import('@/views/attributes/page/Color.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'category',
          name: 'CategoryAttributes',
          component: () => import('@/views/attributes/page/Category.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser'
            ]
          }
        },
        {
          path: 'brand',
          name: 'BrandAttributes',
          component: () => import('@/views/attributes/page/Brand.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'size',
          name: 'SizeAttributes',
          component: () => import('@/views/attributes/page/Size.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'mix-and-macth',
          name: 'MixAndMacthAttributes',
          component: () => import('@/views/attributes/page/MixAndMatchPricing.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'accounting_manager',
              'developer'
            ]
          }
        },
        {
          path: 'collection',
          name: 'CollectionAttribute',
          component: () => import('@/views/attributes/page/Collection.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'group-category',
          name: 'GroupCategoryAttribute',
          component: () => import('@/views/attributes/page/GroupCategory.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        },
        {
          path: 'foreign-currency',
          name: 'ForeignCurrencyAttributes',
          component: () => import('@/views/attributes/page/ForeignCurrency.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'accounting_manager',
              'support',
              'marketing',
              'developer'
            ]
          }
        }
      ]
    },
    {
      path: 'attributes-assigner',
      component: () => import('@/views/attributes-assigner/index.vue'),
      children: [
        {
          path: '',
          name: 'AttributesAssigner',
          component: () => import('@/views/attributes-assigner/pages/Assigner.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'marketing',
              'developer',
              'merchandising_planner',
              'accounting_manager',
              'merchandiser',
              'online_admin',
              'support'
            ]
          }
        }
      ]
    },
    {
      path: 'product-notified',
      name: 'ProductNotified',
      component: () => import('@/views/products/page/ProductNotified.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'marketing',
          'developer',
          'merchandising_planner',
          'accounting_manager',
          'merchandiser',
          'online_admin',
          'support'
        ]
      }
    }
  ]
}
