import Vue from 'vue'
import VueI18n from 'vue-i18n'

import buttonEN from '@/assets/locale/button/en.json'
import buttonTH from '@/assets/locale/button/th.json'
import errorEN from '@/assets/locale/error/en.json'
import errorTH from '@/assets/locale/error/th.json'
import fieldsEN from '@/assets/locale/fields/en.json'
import fieldsTH from '@/assets/locale/fields/th.json'
import menuEN from '@/assets/locale/menu/en.json'
import menuTH from '@/assets/locale/menu/th.json'
import modalEN from '@/assets/locale/modal/en.json'
import modalTH from '@/assets/locale/modal/th.json'
import snackbarEN from '@/assets/locale/snackbar/en.json'
import snackbarTH from '@/assets/locale/snackbar/th.json'

import pageNewReportEN from '@/assets/locale/page/newReports/en.json'
import pageNewReportTH from '@/assets/locale/page/newReports/th.json'

import validateTH from 'vee-validate/dist/locale/th.json'
import validateEN from 'vee-validate/dist/locale/en.json'

import infoEN from '@/assets/locale/info/en.json'
import infoTH from '@/assets/locale/info/th.json'

Vue.use(VueI18n)

const messages = {
  en: {
    validation: {
      ...validateEN.messages,
      'eng-thValidator': '{_field_} cannot use special character!'
    },
    button: buttonEN,
    menu: menuEN,
    modal: modalEN,
    error: errorEN,
    fields: fieldsEN,
    snackbar: snackbarEN,
    newReport: pageNewReportEN,
    info: infoEN
  },
  th: {
    validation: {
      ...validateTH.messages,
      'eng-thValidator': '{_field_} ไม่สามารถใช้อักษรพิเศษได้!'
    },
    button: buttonTH,
    menu: menuTH,
    modal: modalTH,
    error: errorTH,
    fields: fieldsTH,
    snackbar: snackbarTH,
    newReport: pageNewReportTH,
    info: infoTH
  }
}

Vue.config.productionTip = false

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})
