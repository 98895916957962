export default {
  path: '/stock-count',
  component: () => import('@/views/stockCount/index.vue'),
  children: [
    {
      path: '',
      name: 'StockCountList',
      component: () => import('@/views/stockCount/pages/List.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'create',
      name: 'StockCountCreate',
      component: () => import('@/views/stockCount/pages/Create.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'StockCountEdit',
      component: () => import('@/views/stockCount/pages/Edit.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'preview/:id',
      name: 'StockCountPreview',
      component: () => import('@/views/stockCount/pages/Preview'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'counter-result/:stockCountId/:counterId',
      name: 'StockCounterResult',
      component: () => import('@/views/stockCount/pages/CountResult.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    },
    {
      path: 'adjustment-preview/:stockCountId/:stockAdjustmentId',
      name: 'StockAdjustmentPreview',
      component: () => import('@/views/stockCount/pages/PreviewAdjustment.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'inventory_and_costing',
          'accounting_manager',
          'support'
        ]
      }
    }
  ]
}
