import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import authentication from '@/assets/js/Authentication'
import middlewarePipeline from '@/middleware/Pipeline'
import middleware from '@/middleware'

import productRouter from './Products'
import inventoryRouter from './Inventory'
import lineMyShopRouter from './LineMyShop'
import clickcollectRouter from './ClickCollect'
import settingRouter from './Setting'
import notesRouter from './Notes'
import orderRouter from './Order'
import taxInvoice from './TaxInvoice'
import dashBoardRouter from './Dashboard'
import prototypeRouter from './Prototype'
import managementRouter from './Managements'
import webManagementRouter from './WebManagement'
import qrCodeRouter from './QrCode'
import reportRouter from './Reports'
import pmsRouter from './PMS'
import NewReport from './NewReport'
import CSVToBarcode from './CSVToBarcode'
import CreditNote from './CreditNote'
import Purchases from './Purchases'
import CashReceipt from './CashReceipt'
import StoreReports from './StoreReports'
import RFID from './RFID'
import StockCount from './StockCount'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      middleware: [
        'auth'
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      layout: 'Blank'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/login/index.vue'),
    meta: {
      layout: 'Blank'
    }
  },
  productRouter,
  inventoryRouter,
  lineMyShopRouter,
  settingRouter,
  notesRouter,
  orderRouter,
  taxInvoice,
  dashBoardRouter,
  prototypeRouter,
  pmsRouter,
  managementRouter,
  webManagementRouter,
  qrCodeRouter,
  reportRouter,
  clickcollectRouter,
  NewReport,
  CSVToBarcode,
  CreditNote,
  Purchases,
  CashReceipt,
  StoreReports,
  RFID,
  StockCount,
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/feedBack/index.vue')
  },
  {
    path: '/gen-qr',
    name: 'GenQR',
    component: () => import('@/views/genQR/index.vue')
  },
  {
    path: '/production-plan',
    name: 'ProductionPlan',
    component: () => import('@/views/productionPlan/index.vue'),
    meta: {
      middleware: [
        'auth',
        'management',
        'developer',
        'merchandising_planner',
        'merchandiser',
        'designer'
      ]
    }
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'Blank'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('Route/addRouteHistory', from)
  if (store.getters['Components/errorPage']) {
    store.dispatch('Components/setErrorPage', 0)
  }
  if (to.name === 'PageNotFound') {
    store.dispatch('Components/setErrorPage', 404)
  }
  if (!to.meta.middleware) {
    return next()
  }
  const middlewareText = to.meta.middleware

  const context = {
    to,
    from,
    router,
    store,
    next,
    skip: next,
    auth: authentication
  }

  return middleware[middlewareText[0]]({
    ...context,
    next: middlewarePipeline(context, middlewareText, 1)
  })
})

export default router
