import Vue from 'vue'
import baseLayout from '@/components/BaseLayout'
import svgIcon from '@/components/SvgIcon'
import snackbar from '@/components/Snackbar'
import textField from '@/components/form/TextField'
import autocomplete from '@/components/form/Autocomplete'
import combobox from '@/components/form/Combobox'
import textarea from '@/components/form/Textarea'
import dropFile from '@/components/form/DropFile'
import select from '@/components/form/Select'
import Datepicker from '@/components/form/Datepicker.vue'
import iconHover from '@/components/IconHover'
import productImage from '@/components/ProductImage'
import gwShortLogo from '@/components/GwShortLogo'

import newTextField from '@/components/newform/TextField.vue'
import newSelectField from '@/components/newform/SelectField.vue'
import newCombobox from '@/components/newform/Combobox.vue'
import newTextarea from '@/components/newform/TextareaField.vue'
import newDatepicker from '@/components/newform/Datepicker.vue'
import newAutocomplete from '@/components/newform/Autocomplete.vue'

Vue.component('gw-base-layout', baseLayout)
Vue.component('gw-short-logo', gwShortLogo)
Vue.component('gw-svg-icon', svgIcon)
Vue.component('gw-snackbar', snackbar)
Vue.component('gw-text-field', textField)
Vue.component('gw-combobox', combobox)
Vue.component('gw-date-picker', Datepicker)
Vue.component('gw-textarea', textarea)
Vue.component('gw-drop-file', dropFile)
Vue.component('gw-select', select)
Vue.component('gw-autocomplete', autocomplete)
Vue.component('gw-icon-hover', iconHover)
Vue.component('gw-product-image', productImage)

Vue.component('text-field-form', newTextField)
Vue.component('select-field-form', newSelectField)
Vue.component('combobox-form', newCombobox)
Vue.component('autocomplete-form', newAutocomplete)
Vue.component('text-area-form', newTextarea)
Vue.component('date-picker-form', newDatepicker)
