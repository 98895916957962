export const defaultOrderData = {
  brand: null,
  channel: '',
  createdAt: '',
  createdBy: {},
  currentState: 'prepare_create',
  discount: 0,
  expire: '',
  id: '',
  member: {},
  net: 0,
  note: '',
  orderId: '',
  payments: [],
  dhlPackages: [],
  shipping: {},
  shippingAddress: {},
  skus: [],
  stateHistories: [],
  promotionCode: {},
  status: '',
  store: {},
  updatedAt: '',
  updatedBy: {},
  url: '',
  gotMember: false,
  verifiedCode: false
}

export const defaultOrderSku = {
  amount: 0,
  brand: '',
  categories: [],
  code: '',
  color: '',
  description: '',
  discountPrice: 0,
  fullPrice: 0,
  id: '',
  images: [],
  isOnweb: false,
  mixAndMatch: false,
  name: '',
  option: '',
  price: 0,
  productId: '',
  size: '',
  tags: [],
  unitPrice: 0,
  variant: 'SKU',
  discountPercent: 0,
  discountType: 'amount',
  warehouse: {}
}

export const defaultOrderPromotion = {
    id: 0,
    code: '',
    discountNumber: 0,
    discountType: '',
    maxPrice: 0,
    minPrice: 0,
    perUser: 0,
    dateRange: {
      startDate: {},
      endDate: {}
    },
    amount: 0,
    channel: '',
    productTagIds: {
    },
    productCategoryIds: {
    },
    note: '',
    campaignType: '',
    status: '',
    discountPrice: 0
}

export const actionButtons = [
  {
    label: 'BC SYNC',
    function: 'bcSyncSaleInvoice',
    orderChannel: ['web', 'online_international', 'chatbot', 'chatbot_international', 'pos', 'tiktok'],
    color: '#007E87',
    otherChannelStatus: {
      main: [
        'waiting',
        'completed',
        'void',
        'exchange_return'
      ],
      sub: []
    }
  },
  {
    label: 'Create Order',
    function: 'createOrder',
    orderChannel: ['web'],
    otherChannelStatus: {
      main: [
        'prepare_create'
      ],
      sub: []
    }
  },
  {
    label: 'สรุปออเดอร์',
    function: 'openSummaryDialog',
    orderChannel: ['web', 'pos'],
    otherChannelStatus: {
      main: [
        'pending',
        'paid',
        'reserved',
        'waiting',
        'expired',
        'ready_to_ship',
        'completed'
      ],
      sub: []
    }
  },
  {
    label: 'Print Receipt',
    function: 'printReceipt',
    orderChannel: ['web', 'pos'],
    otherChannelStatus: {
      main: [
        'paid',
        'ready_to_ship',
        'completed'
      ],
      sub: []
    }
  },
  {
    label: 'Print Label',
    function: 'printLabel',
    orderChannel: ['web', 'pos'],
    otherChannelStatus: {
      main: [],
      sub: [
        'paid',
        'ready_to_ship',
        'completed'
      ]
    }
  },
  {
    label: 'Ready to Ship',
    function: 'readyToShip',
    orderChannel: ['web', 'manual'],
    otherChannelStatus: {
      main: [
        'paid'
      ],
      sub: []
    }
  },
  {
    label: 'Add Tracking No.',
    function: 'addTrackingNo.',
    orderChannel: ['web'],
    excludeChannel: ['tiktok'],
    otherChannelStatus: {
      main: [],
      sub: [
        'ready_to_ship',
        'completed'
      ]
    }
  },
  {
    label: 'ส่ง DHL',
    function: 'dhl',
    orderChannel: ['web', 'online_international', 'chatbot', 'chatbot_international'],
    excludeChannel: ['tiktok'],
    excludeMethod: ['dhl_express'],
    color: '#FFCC00',
    otherChannelStatus: {
      main: [],
      sub: [
        'ready_to_ship',
        'completed'
      ]
    }
  },
  {
    label: 'ส่ง DHL Express',
    function: 'dhlExpress',
    orderChannel: ['web', 'online_international', 'chatbot', 'chatbot_international', 'international_manual'],
    excludeChannel: ['tiktok'],
    excludeMethod: ['EMS'],
    oneTimeShipment: true,
    color: '#D2002E',
    otherChannelStatus: {
      main: [],
      sub: [
        'ready_to_ship',
        'completed'
      ]
    }
  },
  {
    label: 'ส่ง Kerry',
    function: 'shipKerry',
    orderChannel: ['web', 'manual'],
    excludeChannel: ['tiktok'],
    excludeMethod: ['dhl_express'],
    color: '#f37025',
    otherChannelStatus: {
      main: [],
      sub: [
        'ready_to_ship',
        'completed'
      ]
    }
  },
  {
    label: 'Complete',
    function: 'complete',
    orderChannel: ['web', 'pos'],
    color: '#155e74',
    otherChannelStatus: {
      main: [
        'ready_to_ship'
      ],
      sub: [
        'ready_to_ship'
      ]
    }
  },
  // {
  //   label: 'Void Order',
  //   function: 'voidOrder',
  //   orderChannel: ['web', 'pos'],
  //   otherChannelStatus: {
  //     main: [
  //       'pending'
  //       'paid',
  //       'waiting',
  //       'ready_to_ship',
  //       'completed'
  //     ],
  //     sub: []
  //   }
  // },
  {
    label: 'Cancel',
    function: 'cancel',
    orderChannel: ['web', 'pos'],
    otherChannelStatus: {
      main: [
        'edit'
      ],
      sub: []
    }
  },
  {
    label: 'Save',
    function: 'save',
    orderChannel: ['web', 'pos'],
    otherChannelStatus: {
      main: [
        'edit'
      ],
      sub: []
    }
  },
  {
    label: 'E-TAX',
    function: 'requestETax',
    orderChannel: ['web', 'manual', 'pos'],
    color: '#33a7db',
    otherChannelStatus: {
      main: [
        'completed'
      ],
      sub: []
    }
  },
  {
    label: 'VAT REFUND',
    function: 'requestVRT',
    orderChannel: ['pos'],
    color: '#33a7db',
    otherChannelStatus: {
      main: [
        'completed'
      ],
      sub: []
    }
  }
]

export default {
  defaultOrderData,
  defaultOrderSku,
  actionButtons
}
