export default {
  path: '/management',
  component: () => import('@/views/management/index.vue'),
  children: [
    {
      path: '/members',
      name: 'MemberManagement',
      component: () => import('@/views/management/pages/Members.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/member-settings',
      component: () => import('@/views/memberSettings/index.vue'),
      children: [
        {
          path: '',
          name: 'MemberSettings',
          redirect: { name: 'MemberTagSetting' }
        },
        {
          path: 'tags',
          name: 'MemberTagSetting',
          component: () => import('@/views/memberSettings/pages/MemberTagSetting.vue'),
          meta: {
            middleware: [
              'auth',
              'management',
              'area_manager',
              'accounting_manager',
              'marketing',
              'developer',
              'online_admin',
              'support'
            ]
          }
        }
      ]
    },
    {
      path: '/celebs',
      name: 'CelebsManagement',
      component: () => import('@/views/management/pages/Celebs.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'online_admin',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: '/careers',
      name: 'Careers',
      component: () => import('@/views/management/pages/Careers.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer'
        ]
      }
    },
    {
      path: '/supplier-apply',
      name: 'SupplierApply',
      component: () => import('@/views/management/pages/Supplier.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer'
        ]
      }
    },
    {
      path: '/members/create',
      name: 'CreateMember',
      component: () => import('@/views/management/pages/CreateMember.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/members/edit/:id',
      name: 'EditMember',
      component: () => import('@/views/management/pages/EditMember.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/members/add-member-spending',
      name: 'AddMemberSpending',
      component: () => import('@/views/management/pages/AddMemberSpending.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/members/check-member-spending',
      name: 'CheckMemberSpending',
      component: () => import('@/views/management/pages/CheckMember.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/members/:id',
      name: 'MemberInfo',
      component: () => import('@/views/management/pages/MemberInfo.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/members/:id/uid',
      name: 'MemberUid',
      component: () => import('@/views/management/pages/MemberUid.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'online_admin',
          'support'
        ]
      }
    },
    {
      path: '/promotions',
      name: 'PromotionManagement',
      component: () => import('@/views/management/pages/Promotions.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/promotions/info/:promotionId/:promotionCode',
      name: 'PromotionInfo',
      component: () => import('@/views/management/pages/PromotionInfo.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/promotions/create',
      name: 'CreatePromotion',
      component: () => import('@/views/management/pages/CreatePromotion.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/promotions/edit/:id',
      name: 'EditPromotion',
      component: () => import('@/views/management/pages/CreatePromotion.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/broadcast',
      name: 'Broadcast',
      component: () => import('@/views/management/pages/Broadcast.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer',
          'support'
        ]
      }
    },
    {
      path: '/sms-logs',
      name: 'SmsLogs',
      component: () => import('@/views/management/pages/SmsLogs.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: '/line-logs',
      name: 'LineLogs',
      component: () => import('@/views/management/pages/LineLogs.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer'
        ]
      }
    },
    {
      path: '/reprint-receipt',
      name: 'ReprintReceipt',
      component: () => import('@/views/reprintReceipt/pages/reprintReceipt.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'accounting_manager',
          'developer',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager'
        ]
      }
    },
    {
      path: '/export-product-ads',
      name: 'ExportProductAds',
      component: () => import('@/views/management/pages/ExportProductAds.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'accounting_manager',
          'marketing',
          'developer'
        ]
      }
    }
  ]
}
