export default {
  path: '/inventory',
  component: () => import('@/views/inventory/index.vue'),
  children: [
    {
      path: '',
      name: 'ProductInventory',
      component: () => import('@/views/inventory/page/ProductInventory.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'designer',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'vm',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'movement',
      name: 'StockMovementNew',
      component: () => import('@/views/inventory/page/ProductStockMovementNew.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'online_admin',
          'warehouse_manager',
          'inventory_and_costing',
          'accounting_manager',
          'accounting',
          'support'
        ]
      }
    },
    {
      path: 'reserve/:skuId',
      name: 'ReserveSource',
      component: () => import('@/views/inventory/page/ReserveSource.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'designer',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'vm',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'stock-movement/:skuId',
      name: 'StockMovement',
      component: () => import('@/views/inventory/page/StockMovement.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'designer',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'vm',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'delivery-notes',
      name: 'DeliveryNoteList',
      component: () => import('@/views/inventory/page/DeliveryNoteList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'creative',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'vm',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'move-products',
      name: 'MoveProducts',
      component: () => import('@/views/inventory/page/MoveProducts.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'developer',
          'accounting_manager',
          'accounting',
          'merchandising_planner',
          'merchandiser',
          'support',
          'warehouse_manager'
        ]
      }
    },
    {
      path: 'withdraw-notes',
      name: 'WithdrawNoteList',
      component: () => import('@/views/inventory/page/WithdrawNoteList.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'creative',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'vm',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    },
    {
      path: 'po-overview',
      name: 'PurchasesOrderOverview',
      component: () => import('@/views/inventory/page/PurchaseOrderOverview.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'accounting_manager',
          'accounting',
          'support',
          'inventory_and_costing',
          'warehouse_manager'
        ]
      }
    },
    {
      path: 'derivery-note-of-po/:orderNo',
      name: 'DeliveryNoteOfPurchaseOrder',
      component: () => import('@/views/inventory/page/DeliveryNoteOfPurchaseOrder.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'accounting_manager',
          'accounting',
          'support',
          'inventory_and_costing',
          'warehouse_manager'
        ]
      }
    },
    {
      path: 'po-orders/:orderNo',
      name: 'PoOrders',
      component: () => import('@/views/inventory/page/PoOrders.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'accounting_manager',
          'accounting',
          'support',
          'inventory_and_costing',
          'warehouse_manager'
        ],
        layout: 'Blank'
      }
    },
    {
      path: 'qr-2-csv',
      name: 'QRToCsv',
      component: () => import('@/views/inventory/page/QRToCsv.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'developer',
          'merchandising_planner',
          'merchandiser',
          'accounting_manager',
          'accounting',
          'support',
          'inventory_and_costing',
          'warehouse_manager'
        ]
      }
    },
    {
      path: 'in-transit-report',
      name: 'InTransitReport',
      component: () => import('@/views/inventory/page/InTransitReport.vue'),
      meta: {
        middleware: [
          'auth',
          'management',
          'area_manager',
          'marketing',
          'developer',
          'creative',
          'merchandising_planner',
          'merchandiser',
          'online_admin',
          'accounting_manager',
          'accounting',
          'support',
          'vm',
          'inventory_and_costing',
          'warehouse_manager',
          'warehouse',
          'store_manager',
          'acting_assist_store_manager',
          'assist_store_manager',
          'sales_staff'
        ]
      }
    }
  ]
}
