<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="rules">
    <v-skeleton-loader
      :loading="loading"
      :height="65"
      type="text"
      class="input-skeleton-loader">
      <p
        v-if="outFieldLabel"
        class="mb-1">
        {{ outFieldLabel }}
        <span
          v-if="isRequiredField"
          class="ml-1 red--text">*</span>
      </p>
      <v-autocomplete
        v-model="innerValue"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners" />
    </v-skeleton-loader>
  </validation-provider>
</template>

<script>
export default {
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: null,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    outFieldLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      innerValue: null
    }
  },
  computed: {
    isRequiredField () {
        return this.rules?.required ?? false
    }
  },
  watch: {
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
